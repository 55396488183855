<template>
  <div :class="$style.skeleton_loading">
    <div v-for="i in row" :key="i" :class="$style.skeleton_loading_item">
      <div :class="$style.skeleton_loading_text" :style="getRandomWidth()"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    row?: number
  }>(),
  {
    row: 5,
  },
)

const getRandomWidth = () => {
  // widthは40~100%でランダム
  return `width: ${Math.floor(Math.random() * 60) + 40}%`
}
</script>

<style module scoped lang="scss">
.skeleton_loading {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  .skeleton_loading_item {
    display: flex;
    gap: 0.75rem;
    .skeleton_loading_text {
      height: 36px;
      @include background-skeleton-loading;
    }
  }
}
</style>
